import type { Order } from '@model/order/Order'
import * as R from 'ramda'
import type { Promotion } from '@model/misc/Promotion'
import type { CurrencyOpt } from '@hooks/useCurrency'
import type { Product } from '@model/product'
import type { CartItem } from '@model/cart/CartItem'
import type { KK_STORE_TYPE } from '@pages/api/proxy'
import type { OrderTotal } from '@model/order/OrderTotal'
import type { Customer } from '@model/customer/Customer'
import type { Currency } from '@model/product/PriceInfo'

interface Input {
  order: Order
  customer: Customer | null
  promotions: Promotion[]
  currencyOptions: CurrencyOpt
  cartItems: CartItem[]
  currency: Currency
}

const getClubOnePointsAvailable = (price: number, rate: number, percentage: number) => {
  if (price === 0 || percentage === 0) return 0
  const discountPercentage = percentage || 0
  return Math.round(((price * discountPercentage) / 100) * rate)
}

interface TotalsPerItem {
  total: number
  finalPriceExTax: number
  product: Product
  priceWithCoDiscount: number
}

export function calculateCoMax({ order, promotions, currencyOptions, cartItems, customer, currency }: Input) {
  const coRateFormer = R.pipe(
    R.defaultTo([]),
    R.find(R.propEq('name', 'UsePoints')),
    R.defaultTo({ custom5: '900' }),
    R.propOr('900', 'custom5'),
    parseFloat
  )(promotions)

  const { taxExemption: coMultiplier } = customer || { taxExemption: '1' }

  const store: KK_STORE_TYPE = 'store2'

  const coRate = coRateFormer / (currency === 'SEK' ? 1 : currencyOptions.rate)

  const getCoPointsAvailable = (obj: TotalsPerItem) => {
    if (!obj.product) return 0
    const percentage = obj.product.custom7 ? parseFloat(obj.product.custom7) : 0
    return getClubOnePointsAvailable(obj.finalPriceExTax, coRate, percentage)
  }

  const totalPerItem = (item: CartItem): TotalsPerItem => {
    const percentage = item.product.custom7 ? parseFloat(item.product.custom7) : 0
    const itemFromOrder = order.orderProducts.find((itemFromOrder) => item.sku === itemFromOrder.sku)

    let totalPrice = itemFromOrder?.finalPriceIncTax ?? item.finalPriceExTax

    const updatedOrderProductPrice = R.pathOr(0, ['product', 'orderProductPrice'])(item)

    if (updatedOrderProductPrice && updatedOrderProductPrice < totalPrice && totalPrice) {
      totalPrice = updatedOrderProductPrice
    }

    return {
      ...item,
      total: totalPrice,
      priceWithCoDiscount: (totalPrice * (100 - percentage)) / 100,
    }
  }

  const applyDiscountToProductPrice = (oTotals: OrderTotal[] = []) => {
    return (item: CartItem) => {
      const { discountPercent = 0 } = oTotals.find(
        (ot) => ot.className === 'ot_df_product_discount' && ot.custom2 === item?.product?.sku
      ) || { value: 0 }

      const { rate } = currencyOptions
      const newPrice = Math.round(((item.finalPriceExTax * (100 - discountPercent)) / 100) * rate)

      return {
        ...item,
        product: {
          ...item.product,
          orderProductPrice: newPrice,
        },
      }
    }
  }

  const app = R.pipe(
    R.map(applyDiscountToProductPrice(order.orderTotals)),
    R.map(totalPerItem),
    R.map(getCoPointsAvailable),
    R.sum
  )

  return app(cartItems)
}
