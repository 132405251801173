import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext, useReducer } from 'react'
import type { AppState, AppStateContextProps } from '@context/reducer'
import { appStateReducer } from '@context/reducer'

const defaultAppState: AppState = {
  customer: null,
  searchTerm: null,
  cart: [],
  activeCategoryPage: 1,
  topOffset: 0,
}
export const AppContext = createContext<AppStateContextProps>({} as AppStateContextProps)

const AppStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = { ...defaultAppState }
  const [state, dispatch] = useReducer(appStateReducer, value)

  const exposed = {
    state,
    dispatch,
  }

  return <AppContext.Provider value={exposed}>{children}</AppContext.Provider>
}

export const useAppState = () => useContext(AppContext)
export default AppStateProvider
