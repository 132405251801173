import type { Currency } from '@model/product/PriceInfo'
import type { Locale } from '@model/locales'
import * as R from 'ramda'

const defaultMaxQuantity = 999

export const getFormattedPrice = (price: number, currency: Currency = 'EUR', short = false): string => {
  return new Intl.NumberFormat(currency === 'SEK' ? 'sv' : 'et-EE', {
    style: 'currency',
    currency: currency,
    ...(short && { maximumSignificantDigits: 2 }),
  }).format(price)
}

export const getMaxQuantity = (n: number) => (n < defaultMaxQuantity ? n : defaultMaxQuantity)

export const roundedPriceAfterCalc = (a: number) => Math.floor(a * 100 + 0.5) / 100
export const roundForLocale = (price: number, locale: Locale) => (locale === 'sv' ? Math.round(price) : price)

const anyOfBeverageModel = R.anyPass([
  R.equals('cl'),
  R.equals('ml'),
  R.equals('dl'),
  R.equals('g'),
  R.equals('kg'),
  R.equals('pcs'),
])
const lastElementIsNotCl = R.compose(R.not, anyOfBeverageModel, R.last)
// @ts-ignore
const handleTheModelStringValue = R.compose(R.map(parseFloat), R.map(R.replace(',', '.')), R.init)

export const getPriceByWeight =
  (translate: (term: string, params?: any) => string) =>
  (model: string, price: number, currency: Currency = 'EUR'): string => {
    if (!model) return ''

    // Let's be sure the model string only contains x+y or x*y or in other cases
    // the calculations will go wrong
    const parseModel = model
      .trim()
      .replace(/[\*xX]/g, ' ')
      .replace(/[\+xX]/g, ' ')
      .split(' ')

    if (lastElementIsNotCl(parseModel)) return ''

    const postfix = R.last(parseModel) as string
    // const factors: Record<string, number> = {
    //   ml: 1000,
    //   cl: 100,
    //   dl: 10,
    // }

    const labels: Record<string, string> = {
      ml: `1 ${translate('product.tile.price.litresymbol')}`,
      cl: translate('product.tile.price.litresymbol'),
      dl: translate('product.tile.price.dlsymbol'),
      g: `1 ${translate('product.tile.price.kilogramsymbol')}`,
      kg: translate('product.tile.price.kilogramsymbol'),
      pcs: translate('product.tile.price.piecesymbol'),
    }

    const parseModelNumbers = handleTheModelStringValue(parseModel)
    const volume = model.includes('+') ? parseModelNumbers.reduce(R.add, 0) : parseModelNumbers.reduce(R.multiply, 1)
    const symbol = labels[postfix] || translate('product.tile.price.litresymbol')
    const baseVolumeFactor = ['ml', 'g'].includes(postfix) ? 1000 : ['cl', 'dl', 'kg'].includes(postfix) ? 100 : 1
    return `${getFormattedPrice((baseVolumeFactor * price) / volume, currency)} / ${symbol}`
  }
