import type { FC } from 'react'
import type { ImageProps } from 'next/legacy/image'
import Image from 'next/legacy/image'

const ProductImage: FC<ImageProps> = (props) => {
  const { src, alt, ...restImageProps } = props

  return (
    <span className="flex justify-center aspect-[2/1]">
      <Image alt={alt} layout={'fill'} src={src} objectFit="contain" {...restImageProps} />
    </span>
  )
}

export default ProductImage
