import type { Product } from '@model/product'
import type { FC } from 'react'
import { getFreeProductInfo } from '@utils/product/old-price-utils'
import { useProduct } from '@hooks/useProduct'
import { useTranslate } from '@hooks/useTranslation'
import { CwsGrid, CwsGridCol, CwsGridRow, CwsP, CwsSpan, CwsImg } from '@components/cws'
import clsx from 'clsx'
import s from './FreeProductBar.module.scss'
import { getFullImageUrl } from '@components/product/helpers'
import Image from 'next/image'
import React from 'react'
import { always, tryCatch } from 'ramda'
import { safeHtml } from '@utils/html'
import * as R from 'ramda'

interface FreeProductBarProps {
  product: Product
}
interface ProductDescriptionObject {
  description: string
  ingredients: string
  nutrition: string
}

export const FreeProductBar: FC<FreeProductBarProps> = ({ product }) => {
  const freeProductResults = getFreeProductInfo(product)

  const freeProduct = useProduct(freeProductResults.value)
  const { translate } = useTranslate()
  let descriptionObject: ProductDescriptionObject | null = null

  if (freeProduct?.description?.startsWith('{')) {
    descriptionObject = tryCatch((json: string) => JSON.parse(json), always(null))(freeProduct?.description)
  }

  if (!freeProduct) return null
  const safeHtmlStr = freeProduct?.description
    ? {
        __html: safeHtml(
          R.type(descriptionObject) !== 'Object' || R.isEmpty(descriptionObject)
            ? freeProduct?.description
            : descriptionObject?.description ?? ''
        ),
      }
    : ''
  const { description } = freeProductResults

  return (
    <section className={clsx('space-y-sm items-stretch p-4 bg-white relative')}>
      <section className={s.readLeft}>
        <CwsGrid width="fluid">
          <CwsGridRow noGutter>
            <CwsGridCol col="12">
              <section className="cws-bg--color-group-grey cws-p-xs">
                <CwsSpan>{translate(description)}</CwsSpan>
              </section>
            </CwsGridCol>
            <CwsGridCol col="12" className="cws-p-xs">
              <CwsGridRow>
                <CwsGridCol col="4">
                  <section className="flex justify-center items-center">
                    {freeProduct.image && (
                      <Image
                        alt={freeProduct.name}
                        height="100"
                        width="75"
                        className="object-contain aspect-[1/1]"
                        src={getFullImageUrl(freeProduct.image)}
                      />
                    )}
                  </section>
                </CwsGridCol>
                <CwsGridCol col="8">
                  <CwsP weight="500">{freeProduct.name}</CwsP>
                  {descriptionObject?.description && (
                    <CwsP size="small" dangerouslySetInnerHTML={safeHtmlStr} hideSpacing />
                  )}
                </CwsGridCol>
              </CwsGridRow>
            </CwsGridCol>
          </CwsGridRow>
        </CwsGrid>
      </section>
    </section>
  )
}
