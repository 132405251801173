import type { FC } from 'react'
import { useMemo } from 'react'
import Link from 'next/link'
import { Button } from '@ui/components/Button'
import PriceLine from '../PriceLine'
import type { ProductItemBaseProps } from '../types'
import s from './ProductListItem.module.scss'
import { ProductImage } from '../ProductImage'
import { extractTitle, getFullImageUrl } from '../helpers'
import { AppRoutes } from 'app/routes'
import { ButtonArrow } from '@ui/icons/button'
import { useCart } from '@hooks/useCart'
import clsx from 'clsx'
import { Badge } from '../Badge'
import { getFormattedPrice } from '@utils/price'
import { CustomFacetNumber } from '@model/product/CustomFacet'
import { useSpecialPrice } from '@hooks/product'
import { useTranslate } from '@hooks/useTranslation'
import { useCurrency } from '@hooks/useCurrency'
import { getSlug } from '@utils/slugs'
import { isSkuForbiddenToAddToCart } from '@utils/product-utils'
import { CwsGrid } from '@components/cws'
export type ProductListItemProps = ProductItemBaseProps

/* Unused - delete it? */
export const ProductListItem: FC<ProductListItemProps> = (props) => {
  const { product } = props

  const {
    image: imageRelativePath,
    manufacturerName,
    price0: price,
    name: title,
    sku,
    id,
    quantity,
    customAttrArray,
  } = product

  const image = useMemo(() => getFullImageUrl(imageRelativePath?.replace('_big', '_medium')), [imageRelativePath])

  const productTitle = extractTitle(product)

  const productSlug = getSlug(title, sku)
  const productHref = AppRoutes.productDetails.get(productSlug)
  const { addToCartWithSideEffects } = useCart('shopping cart')
  const handleAddToCart = () => addToCartWithSideEffects({ productId: id, sku, quantity: 1 })
  const { translate } = useTranslate()

  const isOutOfStock = !quantity || quantity < 1

  const campaign = customAttrArray?.find((attr) => attr.facetNumber === CustomFacetNumber.Campaign)?.value

  const normalPrice = price
  const { currency } = useCurrency()

  const specialPrice = useSpecialPrice(product)

  return (
    <div className={clsx(s.product, isOutOfStock && 'opacity-50')}>
      <Link passHref href={productHref} className={s.img}>
        <ProductImage width="100" height="100" src={image} alt={product.sku} />
      </Link>
      <div className="flex flex-[1_1_auto] flex-row mx-lg items-center overflow-hidden ">
        <div className="flex flex-col space-y-xs overflow-hidden overflow-ellipsis">
          {campaign && (
            <div className="justify-self-start mr-auto">
              <Badge>{translate(campaign as any)}</Badge>
            </div>
          )}
          <p className="text-base font-normal leading-5">{manufacturerName}</p>
          <Link
            passHref
            href={productHref}
            className="text-base font-medium leading-5 max-h-10 overflow-hidden overflow-ellipsis"
          >
            {productTitle}
          </Link>
        </div>
      </div>

      <div className="flex flex-[0_0_180px] border-l border-r border-group-grey p-4 items-center">
        <div className="flex flex-1-auto flex-col gap-y-2">
          {!!specialPrice ? (
            <>
              <CwsGrid className="cws-py-xs" width="fluid">
                <PriceLine
                  label={translate('w2.product.detail.price.title') as string}
                  value={specialPrice}
                  dotted
                  size="sm"
                  labelClassName="text-sm"
                  valueClassName="border-b border-notice border-dotted"
                  valueColor={'notice'}
                />
              </CwsGrid>
              <div className="flex flex-row justify-between">
                <p className="text-sm text-dark-grey font-normal text-left">{translate('product.tile.price.normal')}</p>
                <p className="text-sm text-dark-grey font-medium text-right">
                  {getFormattedPrice(normalPrice, currency)}
                </p>
              </div>
            </>
          ) : (
            <CwsGrid className="cws-py-xs" width="fluid">
              <PriceLine
                label={translate('product.tile.price.normal') as string}
                value={normalPrice}
                size="sm"
                labelClassName="text-sm"
                valueColor={'midnight'}
              />
            </CwsGrid>
          )}
        </div>
      </div>
      {/* </div> */}
      <div className={s.buttons}>
        {isOutOfStock ? (
          <>
            <Button size="sm" onClick={handleAddToCart}>
              {translate('product.tile.out.of.stock')}
            </Button>
          </>
        ) : (
          <>
            <Button size="sm" onClick={handleAddToCart} className={clsx(isSkuForbiddenToAddToCart(sku) && 'invisible')}>
              {translate('common.add.to.cart')}
            </Button>
            <Link passHref href={productHref} className="m-auto">
              <Button size="sm" variant="text" className="mt-xs">
                <span className="text-buoy-link font-medium text-sm leading-5 normal-case mr-1">
                  {translate('order.history.details.open.details')}
                </span>
                <ButtonArrow color="buoy-link" className="text-action-dark" />
              </Button>
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

export default ProductListItem
