import type { Dispatch, FC, SetStateAction } from 'react'
import { useRef, useState } from 'react'
import type { Product } from '@model/product'
import { CwsCounter, CwsIcon, CwsP } from '@components/cws'
import { getFullImageUrl } from '@components/product/helpers'
import Image from 'next/image'
import { useTranslate } from '@hooks/useTranslation'
import type { ItemsQuantityState } from '@hooks/useMultipleItemsQuantity'
import CheckMark from '@ui/components/Check/assets/CheckMark.svg'
import { useCurrency } from '@hooks/useCurrency'
import { getFormattedPrice, getMaxQuantity } from '@utils/price'
import { getPriceValueWithCurrency } from '@utils/currency'
import { useInitialData } from '@hooks/useInitialData'
import { useClickAway } from '@ui/hooks/useClickAway'
import moduleStyles from './Bundle.module.scss'
import clsx from 'clsx'
import { SelectedProductValue } from '@components/product/BundleOptions/BundleOptions'

interface SelectItemProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  product: Product
  itemQuantityCallback(sku: string, value: number): void
  setCurrentProduct: Dispatch<SetStateAction<Product | undefined>>
  setUnUsedProducts: Dispatch<SetStateAction<Product[]>>
  currentProduct?: Product
  setSelectedMap: Dispatch<SetStateAction<Record<number, SelectedProductValue>>>
  selectNumber: number
}

const SelectItem: FC<SelectItemProps> = ({
  itemQuantityCallback,
  currentProduct,
  setCurrentProduct,
  setIsOpen,
  product,
  setUnUsedProducts,
  setSelectedMap,
  selectNumber,
}) => {
  const { currency } = useCurrency()
  const { currencies } = useInitialData()
  const normalPrice = getPriceValueWithCurrency(product.price0, currency, currencies)

  return (
    <div
      className="flex justify-between py-2 px-4 items-center hover:bg-white border-b-2 hover:hover:bg-seafoam cursor-pointer"
      onClick={() => {
        itemQuantityCallback(product.sku, 1)
        currentProduct && itemQuantityCallback(currentProduct?.sku, 0)
        setSelectedMap((prevState) => {
          const copyState = { ...prevState }
          if (currentProduct && currentProduct.id === product.id) {
            copyState[selectNumber] = null
            return copyState
          }
          copyState[selectNumber] = { product: product, quantity: product?.quantity }
          return copyState
        })

        setUnUsedProducts((prevState) => {
          const products = prevState.filter((item) => item.id !== product.id)

          return currentProduct ? [...products, currentProduct] : products
        })
        setCurrentProduct((prevState) => {
          if (prevState?.id === product.id) {
            return
          } else {
            return product
          }
        })
      }}
    >
      <div className="">
        {currentProduct?.id !== product.id ? (
          <>
            {product.image && (
              <Image
                src={getFullImageUrl(product.image)}
                alt={product.name}
                className={moduleStyles.bundleImage}
                width={64}
                height={64}
              />
            )}
          </>
        ) : (
          <div className="flex justify-center items-center w-16 h-16 bg-midnight">
            <CheckMark color="white" />
          </div>
        )}
      </div>
      <div className="px-2">
        <CwsP size="small" hideSpacing>
          {product.manufacturerName}
        </CwsP>
        <CwsP hideSpacing weight="500">
          {product.name}
        </CwsP>
      </div>
      <CwsP weight="500" onClick={() => setIsOpen((prevState) => !prevState)}>
        {getFormattedPrice(normalPrice, currency)}
      </CwsP>
    </div>
  )
}

interface BundleItemProps {
  bundleIncludedProducts?: Product[]
  isMainItem?: boolean
  selected?: boolean
  itemQuantityCallback(sku: string, value: number): void
  quantityState: ItemsQuantityState
  setUnUsedProducts: Dispatch<SetStateAction<Product[]>>
  product: Product | undefined
  setSelectedMap: Dispatch<SetStateAction<Record<number, SelectedProductValue>>>
  selectNumber: number
}

export const BundleItem: FC<BundleItemProps> = ({
  bundleIncludedProducts,
  itemQuantityCallback,
  isMainItem,
  quantityState,
  setUnUsedProducts,
  product,
  setSelectedMap,
  selectNumber,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { translate } = useTranslate()
  const [currentProduct, setCurrentProduct] = useState<Product | undefined>(product)
  const { currency } = useCurrency()
  const { currencies } = useInitialData()
  const normalPrice = getPriceValueWithCurrency(currentProduct?.price0 || 0, currency, currencies)
  const selectRef = useRef<HTMLDivElement>(null)
  useClickAway(selectRef, () => setIsOpen(false))
  return (
    <div className="flex gap-y-1 flex-col relative" ref={selectRef}>
      {!!currentProduct ? (
        <div
          className="flex py-2 border-dotted border-b-2 justify-between items-center cursor-pointer"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          {currentProduct?.image && (
            <Image
              src={getFullImageUrl(currentProduct?.image)}
              alt={currentProduct?.name}
              className={moduleStyles.bundleImage}
              width={64}
              height={64}
            />
          )}
          <div className="w-1/3">
            <CwsP hideSpacing weight="500" className="truncate">
              {currentProduct?.name}
            </CwsP>
          </div>
          <CwsIcon icon="arrow-down" size="16" />
          <div className="w-1/4 flex justify-center">
            <div onClick={(e) => e.stopPropagation()}>
              <CwsP className="w-full text-center" weight="500">
                {getFormattedPrice(normalPrice, currency)}
              </CwsP>
              <CwsCounter
                insert
                onChange={(value: number) => {
                  itemQuantityCallback(currentProduct?.sku, value)
                  if (value === 0) {
                    itemQuantityCallback(currentProduct?.sku, value)
                    setSelectedMap((prevState) => {
                      const copyState = { ...prevState }
                      copyState[selectNumber] = null
                      return copyState
                    })
                    setUnUsedProducts((prevState) => {
                      const products = prevState.filter((item) => item.id !== currentProduct.id)

                      return currentProduct ? [...products, currentProduct] : products
                    })
                    setIsOpen(false)
                    setCurrentProduct(undefined)
                  }
                }}
                value={quantityState[currentProduct.sku] || 1}
                max={getMaxQuantity(currentProduct.quantity)}
                min={0}
                size={isMainItem ? 'large' : 'medium'}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex justify-between px-2 py-4 border-dotted border-b-2"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <CwsP weight="500" hideSpacing>
            {translate('product.bundle.select')}
          </CwsP>
          <CwsIcon icon="arrow-down" size="16" />
        </div>
      )}
      {isOpen ? (
        <div
          className={clsx(
            'w-full absolute top-4 z-10 bg-white border-[1px] border-dark-grey last:border-b-1',
            moduleStyles.bundleContainer,
            !!currentProduct ? 'translate-y-16' : 'translate-y-10'
          )}
          onClick={() => setIsOpen(false)}
        >
          {(!currentProduct
            ? [...(bundleIncludedProducts ?? [])]
            : [currentProduct, ...(bundleIncludedProducts ?? [])]
          ).map((item) => (
            <SelectItem
              key={item.id}
              product={item}
              currentProduct={currentProduct}
              itemQuantityCallback={itemQuantityCallback}
              setIsOpen={setIsOpen}
              setCurrentProduct={setCurrentProduct}
              setUnUsedProducts={setUnUsedProducts}
              setSelectedMap={setSelectedMap}
              selectNumber={selectNumber}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}
